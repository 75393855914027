const scroll = (target: HTMLElement) => {
  if (!target) return;
  if (!target.parentElement) return;
  const parentSpeechContainer = target.parentElement.closest(
    ".ualter-sound-speech-container"
  );
  if (!parentSpeechContainer) return;
  parentSpeechContainer.scrollTop = target.offsetTop;
};

export default scroll;
