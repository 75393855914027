interface MySvgProps {
  className?: string;
  onClick?: () => void;
  color?: string;
}

const MySvgComponent: React.FC<MySvgProps> = ({
  className,
  onClick,
  color,
}) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    className={className}
    onClick={onClick}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title></title>{" "}
      <g id="Complete">
        {" "}
        <g id="volume-off">
          {" "}
          <g>
            {" "}
            <line
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              x1="3"
              x2="21"
              y1="3"
              y2="21"
            ></line>{" "}
            <polyline
              fill="none"
              points="9.9 5.5 12 3 12 21 7 16 2 16 2 9 3 9 7 9 8 9"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            ></polyline>{" "}
            <path
              d="M21.4,16a8.5,8.5,0,0,0,1.1-4.2c0-4.3-3.1-7.8-7-7.8"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            ></path>{" "}
            <path
              d="M15.5,20.5a5.2,5.2,0,0,0,3-1"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            ></path>{" "}
            <path
              d="M18.3,13a2.7,2.7,0,0,0,.2-1.1,2.9,2.9,0,0,0-3-2.9"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            ></path>{" "}
          </g>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);

export default MySvgComponent;
