type NavItemProps = {
  isSelected: boolean;
  text: string;
  onClick: () => void;
};

const NavItemComponent: React.FC<NavItemProps> = ({
  isSelected,
  onClick,
  text,
}) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <div>{text}</div>
      {isSelected && (
        <div
          className="ualter-sound-nav-item-underline h-[2px] w-[50%]
          mx-auto transition-all duration-300 ease-in-out"
        ></div>
      )}
    </div>
  );
};

export default NavItemComponent;
