const checkIfElementIsVisible = (
  element: HTMLElement,
  container: HTMLDivElement
) => {
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const isVerticallyVisible =
    elementRect.top >= containerRect.top &&
    elementRect.bottom <= containerRect.bottom;

  const isHorizontallyVisible =
    elementRect.left >= containerRect.left &&
    elementRect.right <= containerRect.right;

  return isVerticallyVisible && isHorizontallyVisible;
};

export default checkIfElementIsVisible;
