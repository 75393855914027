import React, { useEffect, useState } from "react";
import { Accordion } from "flowbite-react";
import Quote, { quoteInterface } from "../Quote";
import ImageArrow from "../../assets/Arrow";
import scrollToQuote from "../../utils/scrollToQuote";

export interface datosInterface {
  categoria: string;
  datos: Array<{
    numero: string;
    descripcion: string;
    end: number;
    start: number;
    contexto_original: string;
    words: Array<quoteInterface>;
  }>;
}

type DatosProps = {
  datos: Array<datosInterface>;
  speaker: { name: string; familyName: string };
  onPlayQuote: (quoteContainerId: string, start: number, end: number) => void;
  onStop: () => void;
  onKeepPlaying: (timestamp: number) => void;
  showSharePopup: (timeStart?: string, timeEnd?: string) => void;
};

const convertNumberToSpanishText = (numStr: string): string => {
  const num = parseInt(numStr, 10);
  if (isNaN(num)) return numStr;

  if (num < 10_000) return numStr;

  const units = [
    { value: 1_000_000_000_000, text: "billones" },
    { value: 1_000_000_000, text: "mil millones" },
    { value: 1_000_000, text: "millones" },
    { value: 1_000, text: "mil" },
  ];

  for (const unit of units) {
    if (num >= unit.value) {
      const quotient = Math.floor(num / unit.value);
      return `${quotient} ${unit.text}`;
    }
  }

  return num.toString();
};

const DatosComponent: React.FC<DatosProps> = React.memo(
  ({ datos, speaker, onPlayQuote, onStop, onKeepPlaying, showSharePopup }) => {
    const [accordionContent, setAccordionContent] = useState<Array<any>>([]);
    useEffect(() => {
      if (datos.length > 0 && datos[0].datos.length) {
        setAccordionContent(
          datos?.map((categoria, categoryIndex) => (
            <div key={categoryIndex}>
              {/* Removed hardcoded  bg-[#f9f8f8] class for gray background */}
              <div
                className="grid grid-cols-10 w-[100%] px-2 py-2 font-bold items-center
                  text-[#111111] uppercase text-[14px] border-b-[1px] border-gray-150
                  @md:grid-cols-12 @md:pr-[24px]"
              >
                <div className="col-span-7 pl-1 @md:col-span-8">
                  {categoria.categoria}
                </div>
                <div className="col-span-2 col-start-8 pr-1 @md:col-start-10 ml-2">
                  Valor
                </div>
              </div>
              <Accordion
                className="border-0 mb-[60px]"
                alwaysOpen={false}
                collapseAll={true}
                arrowIcon={ImageArrow}
              >
                {categoria.datos
                  ?.filter((dato) => dato !== null)
                  .map((dato, dataIndex) => (
                    <Accordion.Panel key={dataIndex}>
                      {/* Removed hardcoded class  bg-[#f9f8f8] for gray background */}
                      <Accordion.Title className="p-0 focus:ring-0 pr-1 relative @md:pr-[20px] hover:bg-[#c6e2fa]">
                        <div
                          className="w-[100%] absolute left-0 right-0 top-0 bottom-0 h-[100%]"
                          onClick={(
                            event: React.MouseEvent<HTMLDivElement>
                          ) => {
                            const currentElem = event.currentTarget;
                            setTimeout(() => {
                              scrollToQuote(
                                currentElem.parentElement
                                  ?.parentElement as HTMLElement
                              );
                            }, 50);
                          }}
                        ></div>
                        <div
                          className="grid grid-cols-10 px-2 py-2 items-center w-[100%]
                            text-[#333333] leading-[1.2] text-[16px] @md:grid-cols-12"
                        >
                          <div className="col-span-7 pl-1 @md:col-span-8">
                            {dato.descripcion}
                          </div>
                          <div className="col-span-2 col-start-8 pl-3 @md:col-start-10 ml-2">
                            {convertNumberToSpanishText(dato.numero)}
                          </div>
                        </div>
                      </Accordion.Title>
                      <Accordion.Content className="p-0">
                        <div
                          key={dataIndex}
                          id={`ualter-sound-quote-datos-${categoryIndex}-${dataIndex}`}
                        >
                          <Quote
                            type="numbers"
                            quoteIndex={dataIndex}
                            start={dato.start}
                            end={dato.end}
                            words={dato.words}
                            speaker={speaker}
                            onPlay={() => {
                              onPlayQuote(
                                `ualter-sound-quote-datos-${categoryIndex}-${dataIndex}`,
                                dato.start,
                                dato.end
                              );
                            }}
                            onStop={onStop}
                            onKeepPlaying={() => onKeepPlaying(dato.end)}
                            showSharePopup={showSharePopup}
                          />
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                  ))}
              </Accordion>
            </div>
          ))
        );
      }
    }, [datos, onPlayQuote, onKeepPlaying, onStop, speaker, showSharePopup]);
    return <div className="font-dmsans leading-[20px]">{accordionContent}</div>;
  }
);

export default DatosComponent;
