type DateProps = {
  text: string;
};

const Component: React.FC<DateProps> = ({ text }) => {
  return (
    <div className="text-center font-semibold text-[18px] text-[#696b6e]">
      {text}
    </div>
  );
};

export default Component;
