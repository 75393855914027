import ImageShare from "../assets/Share";

interface ShareButtonProps {
  onClick: () => void;
}

const ShareButton: React.FC<ShareButtonProps> = ({ onClick }) => {
  return (
    <div
      className="popup w-fit select-none shadow-lg ualter-sound-share-button
        border-[2px] border-[#ccc] px-3 py-1 z-[1000] rounded-[20px] flex items-center justify-between"
    >
      <button
        onTouchEnd={(e) => {
          e.stopPropagation();
          onClick();
        }}
        onClick={onClick}
        className="flex items-center"
      >
        Compartir
        <ImageShare className="ml-[0.5rem] ualter-sound-svg-secondary" />
      </button>
    </div>
  );
};

export default ShareButton;
