import BrandLogo from "../BrandLogo";

const Head = () => (
  <div className="flex justify-between items-center px-3 py-0.5 bg-white rounded-t-[12px]">
    <BrandLogo />
    <a
      href="https://ualtersound.ai"
      className="font-dmsans text-[#dd2a00] text-[18px] text-[#dd2a00]" // ualter-sound-header-link"
    >
      UalterSound
    </a>
  </div>
);

export default Head;
