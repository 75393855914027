import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useCallback,
} from "react";
import ImagePlay from "../assets/Play";
import ImagePause from "../assets/Pause";
import Volume from "../assets/Volume";
import VolumeOff from "../assets/VolumeOff";
import ImageShare from "../assets/Share";
import ReactPlayer from "react-player/file";
import checkIfElementIsVisible from "../utils/checkIfElementIsVisible";
import { usePlayingContext } from "../context/PlayingContext";
import { useQuotesContext } from "../context/QuotesContext";

type AudioPlayerProps = {
  disabled: boolean;
  articleId: string;
  widgetHash: string;
  startTime?: number;
  endTime?: number;
  beforePlay?: () => void;
  showSharePopup: (timeStart?: string, timeEnd?: string) => void;
  scrollToElement: (element: HTMLElement) => void;
  setScrollToCurrentVisible: (visible: boolean) => void;
};

export interface AudioPlayerMethods {
  onPlayQuote: (
    timestamp: number,
    container: string,
    stopTimestamp: number
  ) => void;
  onPlay: (timestamp: number, shouldFocus?: boolean, isQuote?: boolean) => void;
  onStop: () => void;
  onResetStopTimestamp: () => void;
}

export function formatSecondsToHMS(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  let formattedTime = "";

  if (hours > 0 && minutes >= 60) {
    formattedTime += `${hours}:`;
  }

  formattedTime += `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;

  return formattedTime;
}

export const AudioPlayerComponent: ForwardRefRenderFunction<
  AudioPlayerMethods,
  AudioPlayerProps
> = (
  {
    disabled,
    articleId,
    widgetHash,
    startTime,
    endTime,
    showSharePopup,
    beforePlay,
    scrollToElement,
    setScrollToCurrentVisible,
  },
  ref
) => {
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const [stopAt, setStopAt] = useState<number | null>(null);
  // const [isPlaying, setIsPlaying] = useState(false);
  const {
    isPlaying,
    updatePlayingState,
    isAutoScrollEnabled,
    disableAutoScroll,
    enableAutoScroll,
    setCurrentWordVisibility,
  } = usePlayingContext();
  const { resetCurrentQuote, currentQuote } = useQuotesContext();
  const [audioVolume, setAudioVolume] = useState(0.5);
  const [audioLength, setAudioLength] = useState(0);
  const audioRef = useRef<any>(null);

  const removeHighlight = useCallback(() => {
    document
      .querySelectorAll(
        `#ualter-sound-speech-${articleId}-${widgetHash} span.ualter-sound-is-playing`
      )
      .forEach((elem) => {
        elem.classList.remove("ualter-sound-is-playing");
      });
  }, [articleId, widgetHash]);

  const onStop = useCallback(async () => {
    await audioRef.current.getInternalPlayer().pause();
    setCurrentWordVisibility(true);
    removeHighlight();
    resetCurrentQuote();
    //updatePlayingState(false);
  }, [
    //updatePlayingState,
    resetCurrentQuote,
    removeHighlight,
    setCurrentWordVisibility,
  ]);

  const onPlayQuote = async (
    timestamp: number,
    container: string,
    stopTimestamp: number
  ) => {
    setStopAt(stopTimestamp);
    await onPlay(timestamp);
    updatePlayingState(true);
  };

  const onPlay = async (
    timestamp: number,
    shouldFocus?: boolean,
    isQuote?: boolean
  ) => {
    setCurrentTimestamp(timestamp);
    audioRef.current.seekTo(timestamp, "seconds");
    updatePlayingState(true);
    await audioRef.current.getInternalPlayer().play();
    if (shouldFocus) {
      enableAutoScroll();
    }
  };

  const onResetStopTimestamp = () => setStopAt(null);

  const setVolume = (v: number) => {
    setAudioVolume(v);
    audioRef.current.getInternalPlayer().volume = v;
  };

  const getCurrentPlayingWordElement = useCallback(() => {
    const element = document.querySelector(
      `#ualter-sound-speech-${articleId}-${widgetHash} .ualter-sound-is-playing`
    ) as HTMLSpanElement;
    return element;
  }, [articleId, widgetHash]);

  const getSpeechContainerElement = useCallback(() => {
    const container = document.getElementById(
      `ualter-sound-speech-${articleId}-${widgetHash}`
    ) as HTMLDivElement;
    return container;
  }, [articleId, widgetHash]);

  const isCurrentPlayingWordVisible = useCallback(() => {
    if (!isPlaying) return true;
    // Bad pattern: this assumes the word is visible just to disable
    //  the scroll to current button
    //  while playing quotes.
    if (currentQuote !== "") return true;
    const element = getCurrentPlayingWordElement();
    if (!element) return false;
    const container = getSpeechContainerElement();
    if (!container) return false;
    return checkIfElementIsVisible(element, container);
  }, [
    getCurrentPlayingWordElement,
    getSpeechContainerElement,
    currentQuote,
    isPlaying,
  ]);

  const scrollEventListener = useCallback(() => {
    disableAutoScroll();
    setCurrentWordVisibility(isCurrentPlayingWordVisible());
  }, [
    disableAutoScroll,
    setCurrentWordVisibility,
    isCurrentPlayingWordVisible,
  ]);

  useImperativeHandle(ref, () => ({
    onPlayQuote,
    onPlay,
    onStop,
    onResetStopTimestamp,
  }));

  useEffect(() => {
    if (startTime) {
      setCurrentTimestamp(startTime);
      audioRef.current.seekTo(startTime, "seconds");
    }
  }, [startTime]);

  useEffect(() => {
    const discursoScrollContainer = document.getElementById(
      `ualter-sound-speech-${articleId}-${widgetHash}`
    ) as HTMLDivElement;
    discursoScrollContainer.addEventListener("scroll", scrollEventListener);
    return () => {
      discursoScrollContainer.removeEventListener(
        "scroll",
        scrollEventListener
      );
    };
  }, [scrollEventListener, articleId, widgetHash]);

  useEffect(() => {
    if (currentQuote !== "" && stopAt && currentTimestamp >= stopAt) {
      onStop();
    }
  }, [stopAt, currentTimestamp, onStop, currentQuote]);

  const findCloserWordToTimestamp = useCallback(
    (timestamp: number) => {
      return Array.from(
        document.querySelectorAll(
          `#ualter-sound-speech-${articleId}-${widgetHash} div.ualter-sound-speech-transcript span.ualter-sound-speech-word`
        )
      )
        .reverse()
        .find((elem) =>
          elem.getAttribute("data-te")
            ? parseFloat(elem.getAttribute("data-te")!) <= timestamp + 0.5
            : false
        );
    },
    [articleId, widgetHash]
  );

  const handleSeek = useCallback(
    (time: any) => {
      if (currentQuote === "") {
        const word = findCloserWordToTimestamp(time);
        if (word) {
          scrollToElement(word as HTMLElement);
        }
      }
    },
    [currentQuote, findCloserWordToTimestamp, scrollToElement]
  );

  const handleProgress = useCallback(
    (progress: any) => {
      const onReset = () => {
        audioRef.current.getInternalPlayer().pause();
        updatePlayingState(false);
        setCurrentTimestamp(startTime || 0);
        audioRef.current.seekTo(startTime || 0, "seconds");
      };

      setCurrentTimestamp(progress.playedSeconds);
      if (endTime && progress.playedSeconds >= endTime) onReset();

      if (isPlaying) {
        removeHighlight();

        const wordToHighlight = findCloserWordToTimestamp(
          progress.playedSeconds
        );
        if (wordToHighlight) {
          const ts = wordToHighlight.getAttribute("data-ts");
          const te = wordToHighlight.getAttribute("data-te");
          document
            .querySelectorAll(
              `#ualter-sound-speech-${articleId}-${widgetHash} .ualter-sound-speech-highlightable span.ualter-sound-speech-word[data-ts="${ts}"][data-te="${te}"]`
            )
            .forEach((elem) => {
              elem.classList.add("ualter-sound-is-playing");
            });
        }
        if (
          currentQuote === "" &&
          wordToHighlight &&
          isAutoScrollEnabled &&
          !checkIfElementIsVisible(
            wordToHighlight as HTMLElement,
            getSpeechContainerElement()
          )
        ) {
          scrollToElement(wordToHighlight as HTMLElement);
        } else {
          setCurrentWordVisibility(isCurrentPlayingWordVisible());
          setScrollToCurrentVisible(!isCurrentPlayingWordVisible());
        }
      }
    },
    [
      startTime,
      endTime,
      isPlaying,
      articleId,
      widgetHash,
      updatePlayingState,
      currentQuote,
      scrollToElement,
      isAutoScrollEnabled,
      removeHighlight,
      getSpeechContainerElement,
      setCurrentWordVisibility,
      isCurrentPlayingWordVisible,
      findCloserWordToTimestamp,
      setScrollToCurrentVisible,
    ]
  );

  return (
    <div
      className="ualter-sound-audio-player py-2 px-2 rounded-b-[12px]
        flex justify-start items-center gap-1 grow w-full"
    >
      <ReactPlayer
        ref={audioRef}
        //url={`${process.env.REACT_APP_AUDIO_URL}/${articleId}/browser.mp3`}
        url={`${disabled? "": process.env.REACT_APP_AUDIO_URL}/${articleId}/browser.mp3`}
        width="1px"
        height="1px"
        className="hidden"
        onSeek={handleSeek}
        onProgress={handleProgress}
        onPlay={() => updatePlayingState(true)}
        onPause={() => {
          document
            .querySelector(
              `#ualter-sound-speech-${articleId}-${widgetHash} .ualter-sound-scroll-to-current`
            )
            ?.classList.add("hidden");
          resetCurrentQuote();
          updatePlayingState(false);
          setStopAt(null);
        }}
        onDuration={(duration) => {
          setAudioLength(duration);
          if (startTime) {
            setCurrentTimestamp(startTime);
            audioRef.current.seekTo(startTime, "seconds");
          }
        }}
        progressInterval={200}
        controls={false}
        volume={0.5}
      />
      {isPlaying ? (
        <ImagePause
          onClick={onStop}
          className="w-[40px] h-[32px] cursor-pointer"
        />
      ) : (
        <ImagePlay
          onClick={() => {
            if (disabled) return;
            if (beforePlay) beforePlay();
            audioRef.current.getInternalPlayer().play();
            enableAutoScroll();
          }}
          className="w-[40px] h-[32px] cursor-pointer"
        />
      )}
      <div className="flex grow">
        <input
          id="ualter-sound-audio-player-seek"
          className="w-full"
          type="range"
          min={startTime ? startTime : 0}
          max={endTime ? endTime : audioLength}
          step="any"
          value={currentTimestamp}
          onChange={(e) => {
            const timestamp = parseFloat(e.target.value);
            setCurrentTimestamp(timestamp);
            audioRef.current.seekTo(timestamp, "seconds");
          }}
        />
      </div>
      <div className="text-[14px] mr-2 font-nunito font-semibold select-none">
        {formatSecondsToHMS(
          startTime ? currentTimestamp - startTime : currentTimestamp
        )}
      </div>
      <div className="ualter-sound-audio-volume-container relative mr-2">
        {!disabled && audioVolume > 0 ? (
          <Volume
            className="ualter-sound-svg-audio-player cursor-pointer"
            onClick={() => setVolume(0)}
          />
        ) : (
          !disabled && (
            <VolumeOff
              className="ualter-sound-svg-audio-player cursor-pointer"
              onClick={() => setVolume(0.5)}
            />
          )
        )}

        <div className="h-[25px] px-[10px] rounded-[20px] hidden -rotate-90 items-center absolute -top-[58px] -left-[40px] ualter-sound-audio-volume">
          <input
            id="ualter-sound-audio-player-volume"
            className=""
            type="range"
            min="0"
            max="1"
            step="any"
            value={audioVolume}
            onChange={(e) => {
              const volume = parseFloat(e.target.value);
              setVolume(volume);
            }}
          />
        </div>
      </div>
      {!disabled && (
        <ImageShare
          className="w-[20px] cursor-pointer mb-[2px] ualter-sound-svg-audio-player"
          onClick={() => {
            if (startTime && endTime) {
              showSharePopup(startTime.toString(), endTime.toString());
              return;
            }
            showSharePopup();
          }}
        />
      )}
    </div>
  );
};

export default forwardRef(AudioPlayerComponent);
