type TitleProps = {
  title: string;
};

const TitleComponent: React.FC<TitleProps> = ({ title }) => {
  return (
    <div className="font-extrabold text-[22px] text-black leading-[1.1] pt-1">
      {/^".*"$/.test(title) ? title : `"${title}"`}
    </div>
  );
};

export default TitleComponent;
