import React, { useEffect, useState } from "react";
import { Accordion } from "flowbite-react";
import Quote, { quoteInterface } from "../Quote";
import scrollToQuote from "../../utils/scrollToQuote";
import ImageArrow from "../../assets/Arrow";

export interface nombresInterface {
  name: string;
  times_mentioned: number;
  text_quotes: Array<string>;
  quotes: Array<{
    word_count: string;
    end: number;
    start: number;
    words: Array<quoteInterface>;
  }>;
}

type NombresProps = {
  nombres: Array<nombresInterface>;
  speaker: { name: string; familyName: string };
  onPlayQuote: (quoteContainerId: string, start: number, end: number) => void;
  onStop: () => void;
  onKeepPlaying: (timestamp: number) => void;
  showSharePopup: (timeStart?: string, timeEnd?: string) => void;
};

const NombresComponent: React.FC<NombresProps> = React.memo(
  ({
    nombres,
    speaker,
    onPlayQuote,
    onStop,
    onKeepPlaying,
    showSharePopup,
  }) => {
    const [accordionContent, setAccordionContent] = useState<Array<any>>([]);
    useEffect(() => {
      setAccordionContent(
        nombres?.map((name, nameIndex) => (
          <Accordion.Panel key={nameIndex}>
            {/* Removed hardcoded class  bg-[#f9f8f8] for gray background */}
            <Accordion.Title
              className="text-black font-semibold p-0 pl-2 pr-[6px] focus:ring-0 relative
                @md:pr-[30px] hover:bg-[#c6e2fa]"
            >
              <div
                className="w-[100%] absolute left-0 right-0 top-0 bottom-0 h-[100%]"
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  const currentElem = event.currentTarget;
                  setTimeout(() => {
                    scrollToQuote(
                      currentElem.parentElement?.parentElement as HTMLElement
                    );
                  }, 50);
                }}
              ></div>
              <div className="grid grid-cols-1 px-2 py-2 w-[100%]">
                <div className="capitalize">{`${name.name}${
                  name.times_mentioned > 1
                    ? " (" + name.times_mentioned + ")"
                    : ""
                }`}</div>
              </div>
            </Accordion.Title>
            <Accordion.Content className="p-0">
              {name.quotes?.map((quote, quoteIndex) => (
                <div
                  key={quoteIndex}
                  id={`ualter-sound-quote-nombres-${nameIndex}-${quoteIndex}`}
                >
                  <Quote
                    type="names"
                    quoteIndex={quoteIndex}
                    start={quote.start}
                    end={quote.end}
                    words={quote.words}
                    speaker={speaker}
                    onPlay={() => {
                      onPlayQuote(
                        `ualter-sound-quote-nombres-${nameIndex}-${quoteIndex}`,
                        quote.start,
                        quote.end
                      );
                    }}
                    onStop={onStop}
                    onKeepPlaying={() => onKeepPlaying(quote.end)}
                    showSharePopup={showSharePopup}
                  />
                </div>
              ))}
            </Accordion.Content>
          </Accordion.Panel>
        ))
      );
    }, [nombres, onPlayQuote, onKeepPlaying, onStop, speaker, showSharePopup]);
    return (
      <Accordion
        collapseAll={true}
        className="border-0 mb-[60px]"
        alwaysOpen={false}
        arrowIcon={ImageArrow}
      >
        {accordionContent}
      </Accordion>
    );
  }
);

export default NombresComponent;
