import React, { useCallback, useEffect, useState } from "react";
import OpenExternal from "../icons/OpenExternal";

export interface whisperWordInterface {
  word: string;
  start: number;
  end: number;
  style?: string;
}

type SpeechProps = {
  words: Array<whisperWordInterface>;
  onClick: (start: number) => void;
  isFragment: boolean;
  fullSpeechURL: string;
  handleSelectText: (
    timeStart: number,
    timeEnd: number,
    y: number | null
  ) => void;
  articleId: string;
  hash: string;
};

const SpeechComponent: React.FC<SpeechProps> = React.memo(
  ({
    words,
    onClick,
    isFragment = false,
    fullSpeechURL,
    handleSelectText,
    articleId,
    hash,
  }) => {
    const [spansElem, setSpansElem] = useState<Array<any>>([]);

    useEffect(() => {
      if (words.length)
        setSpansElem(
          words.map((word, index) => {
            let text = word.word;
            if (!new RegExp(/^(([.|,]([0-9]+))|%)$/).test(text))
              text = " " + text;
            let className = "ualter-sound-speech-word cursor-pointer";
            // if has linebreak but is not the last word
            if (word.style && index + 1 !== words.length)
              className += " after:block after:h-5";
            return (
              <span
                className={className}
                key={index}
                data-ts={word.start}
                data-te={word.end}
                onClick={() => onClick(word.start)}
              >
                {text}
              </span>
            );
          })
        );
    }, [words, onClick]);

    const handleSelection = useCallback(
      (event: MouseEvent | TouchEvent) =>
        requestAnimationFrame(() => {
          const selection = window.getSelection();
          if (!selection || selection.toString().trim() === "") return;

          const container = document.getElementById(
            `ualter-sound-wrapper-${articleId}-${hash}`
          );
          if (
            !container ||
            !container.contains(selection.getRangeAt(0).commonAncestorContainer)
          )
            return; // this means the selection is outside the widget

          const range = selection.getRangeAt(0);
          let { startContainer, endContainer } = range;

          const getNodeTimes = (node: Node) => ({
            start: node.parentElement?.getAttribute("data-ts"),
            end: node.parentElement?.getAttribute("data-te"),
          });

          const startNodeTimes = getNodeTimes(startContainer);
          const endNodeTimes = getNodeTimes(endContainer);

          if (
            !startNodeTimes.start ||
            !startNodeTimes.end ||
            !endNodeTimes.start ||
            !endNodeTimes.end
          )
            return;

          let timeStart = startNodeTimes.start;
          // if the startContainer is not the first word of the selection. Safari bug
          if (
            startContainer.nodeValue?.trim() !==
            selection.toString().trim().split(" ")[0]
          ) {
            const nextWord = words.at(
              words.findIndex(
                (w) => w.start.toString() === startNodeTimes.start
              ) + 1
            );
            if (nextWord) timeStart = nextWord.start.toString();
          }

          let timeEnd = endNodeTimes.end;
          // if the endContainer is not the last word of the selection. Safari bug
          if (
            endContainer.nodeValue?.trim() !==
            selection.toString().trim().split(" ")[
              selection.toString().trim().split(" ").length - 1
            ]
          ) {
            const prevWord = words.at(
              words.findIndex(
                (w) => w.start.toString() === endNodeTimes.start
              ) - 1
            );
            if (prevWord) timeEnd = prevWord.end.toString();
          }

          handleSelectText(
            parseFloat(timeStart),
            parseFloat(timeEnd),
            event instanceof MouseEvent ? event.clientY : null
          );
        }),
      [words, handleSelectText, articleId, hash]
    );

    useEffect(() => {
      document.addEventListener("mouseup", handleSelection);
      document.addEventListener("touchend", handleSelection);

      return () => {
        document.removeEventListener("mouseup", handleSelection);
        document.removeEventListener("touchend", handleSelection);
      };
    }, [handleSelection]);

    return (
      <>
        <div
          className="px-3 @md:px-6 py-4 mx-2 rounded-[5px] ualter-sound-speech-transcript
            ualter-sound-speech-highlightable text-md @md:text-lg"
        >
          {spansElem}
        </div>
        {isFragment && (
          <div className="flex justify-center w-full">
            <a
              className="underline select-none flex space-x-1 items-center"
              href={fullSpeechURL}
            >
              <p>Ver discurso completo</p>
              <OpenExternal color="#000" />
            </a>
          </div>
        )}
      </>
    );
  }
);

export default SpeechComponent;
