interface MySvgProps {
  className?: string;
  onClick?: () => void;
  color?: string;
}

const MySvgComponent: React.FC<MySvgProps> = ({
  className,
  onClick,
  color,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={16}
    height={16}
    viewBox="0 0 512 512"
    className={className}
    onClick={onClick}
  >
    <path
      d="M512 230.431 283.498 44.621v94.807C60.776 141.244-21.842 307.324 4.826 467.379c48.696-99.493 149.915-138.677 278.672-143.14v92.003L512 230.431z"
      style={color ? { fill: color } : {}}
    />
  </svg>
);

export default MySvgComponent;
