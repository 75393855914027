type DateProps = {
  date: string;
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  const formattedDate = date.toLocaleDateString("es-ES", options);

  return formattedDate.toUpperCase();
};

const DateComponent: React.FC<DateProps> = ({ date }) => {
  return <div className="text-[14px] text-right">{formatDate(date)}</div>;
};

export default DateComponent;
