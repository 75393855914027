import React, { FC, ComponentProps } from "react";

const MySvgComponent: FC<ComponentProps<"svg">> = ({ ...props }) => {
  return (
    <svg
      {...props}
      fill="#A4A4A4"
      className={`shrink-0 ${
        props.className?.includes("rotate-180") ? "rotate-180" : ""
      }`}
      width={15}
      height={15}
      viewBox="0 0 32 32"
    >
      <path d="M4.219 10.781 2.78 12.22l12.5 12.5.719.687.719-.687 12.5-12.5-1.438-1.438L16 22.562Z" />
    </svg>
  );
};

export default MySvgComponent;
