import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";

const getElementComputedWidth = (element: HTMLElement) => {
  try {
    return parseFloat(window.getComputedStyle(element).width.replace("px", ""));
  } catch (e) {
    return 0;
  }
};

/**
 * Checks and sets the height of the parent element.
 * In a situation in which the parent element has a height of 0px (or is not set at all),
 * this function sets the height to 670px. This is useful for embedding the Ualter Sound widget
 * in the home page of Clarin. The widget spans the height of two rows when in column.
 * The widget spans the height of the viewport if in mobile view (or desktop with narrow width).
 *
 * @param parent - The parent element.
 * @param shouldHandleParentHeight - Flag indicating whether to handle parent height.
 * @returns The value of shouldHandleParentHeight after setting the parent height.
 */
const checkAndSetParentHeight = (
  parent: HTMLElement,
  shouldHandleParentHeight = false
) => {
  if (parent) {
    const parentHeight = parent.style.height.replace("px", "");
    const parentWidth = getElementComputedWidth(parent);

    if (
      !shouldHandleParentHeight &&
      (parentHeight === "0px" || parentHeight === "" || parentHeight === "0")
    ) {
      shouldHandleParentHeight = true;
      window.addEventListener("resize", () =>
        checkAndSetParentHeight(parent, true)
      );
    }
    if (shouldHandleParentHeight && parentWidth <= 314) {
      parent.style.height = "670px";
      parent.style.maxHeight = "100dvh";
      return shouldHandleParentHeight;
    }

    if (shouldHandleParentHeight && parentWidth > 314) {
      parent.style.height = "90dvh";
      parent.style.maxHeight = "100dvh";
      return shouldHandleParentHeight;
    }

    return false;
  }
};

class UalterSound extends HTMLElement {
  resolveArticleId = (container: HTMLElement) => {
    return (
      container.getAttribute("data-content-id") ||
      container.getAttribute("data-article-id")
    );
  };

  resolveFragmentBoundaries = (container: HTMLElement) => {
    const st =
      container.getAttribute("data-start-at") ||
      new URLSearchParams(window.location.search).get("ts");
    const startTime = st ? parseFloat(st) : undefined;
    const et =
      container.getAttribute("data-end-at") ||
      new URLSearchParams(window.location.search).get("te");
    const endTime = et ? parseFloat(et) : undefined;
    return { startTime, endTime };
  };

  connectedCallback() {
    const container = this;
    const hash = Date.now().toString();
    const articleId = this.resolveArticleId(container);
    const rootContainer = document.createElement("div");
    rootContainer.setAttribute("id", `usc-${articleId}-${hash}`);
    rootContainer.classList.add("ualter-sound-wrapper");
    container.replaceChildren(rootContainer);
    const root = ReactDOM.createRoot(rootContainer);

    if (!articleId || articleId.length < 1) return;
    else {
      checkAndSetParentHeight(rootContainer);
      const { startTime, endTime } = this.resolveFragmentBoundaries(container);
      root.render(
        <App
          articleId={articleId}
          hash={hash}
          startAt={startTime}
          endAt={endTime}
        />
      );
    }
  }
}

customElements.get("ualter-sound") ||
  customElements.define("ualter-sound", UalterSound);
