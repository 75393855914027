type SpeakerProps = {
  speaker: { name: string; familyName: string };
  isExpanded: boolean;
};

const Speaker: React.FC<SpeakerProps> = ({ speaker, isExpanded }) => {
  return (
    <div
      className={`font-bold text-[18px]  ${
        isExpanded ? "opacity-100" : "opacity-60"
      }`}
    >
      {/* <span>{speaker.split(" ").slice(0, -1).join(" ")}</span>
      <span className="uppercase"> {speaker.split(" ").slice(-1)[0]}</span> */}
      <span>
        {speaker.name} {speaker.familyName}
      </span>
    </div>
  );
};

export default Speaker;
