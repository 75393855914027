import React from "react";
import Home from "./screens/Home";

function App(props: {
  articleId: string;
  hash: string;
  startAt?: number;
  endAt?: number;
}) {
  return (
    <Home
      articleId={props.articleId}
      hash={props.hash}
      startTime={props.startAt}
      endTime={props.endAt}
    />
  );
}

export default App;
