import Svg, { SvgProps } from "./Svg";

const OpenExternal: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      {...props}
      width={18}
      height={18}
      pathProps={{
        fill: "none",
        stroke: props.color || "#fff",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 56,
        d: "M384 224v184a40 40 0 01-40 40H104a40 40 0 01-40-40V168a40 40 0 0140-40h167.48M336 64h112v112M224 288L440 72",
      }}
    />
  );
};

export default OpenExternal;
