interface MySvgProps {
  className?: string;
  onClick?: () => void;
}

const MySvgComponent: React.FC<MySvgProps> = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 91.73 91.72"
    className={className}
    onClick={onClick}
  >
    <g
      data-name="Capa_1"
      style={{
        isolation: "isolate",
      }}
    >
      <path
        d="M45.87 0C71.79 0 92.69 21.5 91.7 47.65c-.9 23.77-20.27 43.14-44.05 44.04C21.5 92.68 0 71.78 0 45.86S20.53 0 45.86 0"
        className="ualter-sound-svg-audio-player-background"
      />
      <path
        d="M61.08 45.86 34.1 30.28v31.15l26.98-15.58L34.1 30.27v31.15l26.98-15.58Z"
        className="ualter-sound-svg-audio-player-play"
      />
    </g>
  </svg>
);

export default MySvgComponent;
