import Head from "./Head";
import Date from "./Date";
import Speaker from "./Speaker";
import Title from "./Title";

type HeaderProps = {
  articleNotFound: boolean;
  date: string;
  speaker: { name: string; familyName: string };
  title: string;
  summary: string;
  isExpanded: boolean;
  image: string;
  epigraph: string;
  onClick: () => void;
  isFragment?: boolean;
};

const Header: React.FC<HeaderProps> = ({
  articleNotFound,
  date,
  speaker,
  title,
  summary,
  isExpanded,
  image,
  epigraph,
  onClick,
  isFragment = false,
}) => {
  return (
    <div id="ualter-sound-widget-header">
      <Head />
      <div className="font-dmsans px-[12px] pt-2">
        <div className="text-[#696b6e]">
          <div
            onClick={onClick}
            className="flex justify-between items-center cursor-pointer"
          >
            <Speaker
              speaker={
                articleNotFound
                  ? {
                      name: "Este contenido no está disponible",
                      familyName: "",
                    }
                  : speaker
              }
              isExpanded={isExpanded}
            />
            {!articleNotFound && <Date date={date} />}
          </div>
          {!isFragment && (
            <div
              className={`font-nunito transition-max-height duration-500 ease-in-out overflow-hidden ${
                isExpanded ? "max-h-[800px]" : "max-h-0"
              }`}
            >
              {!articleNotFound && <Title title={title} />}
              {/* <Summary summary={summary} /> */}
              <div className="relative">
                {image && <img className="" src={`${image}`} alt={``} />}
                <div className="absolute bottom-0 text-center right-0 left-0 text-white bg-black bg-opacity-50 text-[14px]">
                  {epigraph}
                </div>
              </div>
              <hr className="w-[95%] mt-4 mx-auto ualter-sound-nav-hr" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
