import React, { createContext, useContext, useState } from "react";

const PlayingContext = createContext({
  isPlaying: false,
  isAutoScrollEnabled: true,
  isCurrentWordVisible: true,
  isKeepPlayingRequested: false,
  updatePlayingState: (data: boolean) => {},
  enableAutoScroll: () => {},
  disableAutoScroll: () => {},
  toggleAutoScroll: () => {},
  setCurrentWordVisibility: (visible: boolean) => {},
  resetKeepPlayingRequest: () => {},
  setKeepPlayingRequest: () => {},
});

export const PlayingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState<boolean>(true);
  const [isKeepPlayingRequested, setIsKeepPlayingRequested] =
    useState<boolean>(false);
  const [isCurrentWordVisible, setIsCurrentWordVisible] =
    useState<boolean>(true);

  const resetKeepPlayingRequest = () => {
    setIsKeepPlayingRequested(false);
  };

  const setKeepPlayingRequest = () => {
    setIsKeepPlayingRequested(true);
  };
  const updatePlayingState = (data: boolean) => {
    setIsPlaying(data);
  };

  const enableAutoScroll = () => {
    setIsAutoScrollEnabled(true);
  };

  const disableAutoScroll = () => {
    setIsAutoScrollEnabled(false);
  };

  const toggleAutoScroll = () => {
    setIsAutoScrollEnabled(!isAutoScrollEnabled);
  };

  const setCurrentWordVisibility = (visible: boolean) => {
    setIsCurrentWordVisible(visible);
  };

  return (
    <PlayingContext.Provider
      value={{
        isPlaying,
        isAutoScrollEnabled,
        isCurrentWordVisible,
        isKeepPlayingRequested,
        updatePlayingState,
        enableAutoScroll,
        disableAutoScroll,
        toggleAutoScroll,
        setCurrentWordVisibility,
        setKeepPlayingRequest,
        resetKeepPlayingRequest,
      }}
    >
      {children}
    </PlayingContext.Provider>
  );
};

export const usePlayingContext = () => {
  return useContext(PlayingContext);
};
