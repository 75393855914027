import React, { useEffect, useState } from "react";
import { Accordion } from "flowbite-react";
import Quote, { quoteInterface } from "../Quote";
import scrollToQuote from "../../utils/scrollToQuote";
import ImageArrow from "../../assets/Arrow";

export interface thematicIndexInterface {
  title: string;
  quotes: Array<{
    end: number;
    start: number;
    text: string;
    words: Array<quoteInterface>;
  }>;
}

type thematicIndexProps = {
  index: Array<thematicIndexInterface>;
  transcriptMetadata: {
    speaker: { name: string; familyName: string };
    date: string;
    place: string;
    articleFileName: string;
  };
  onPlayQuote: (quoteContainerId: string, start: number, end: number) => void;
  onStop: () => void;
  onKeepPlaying: (timestamp: number) => void;
  showSharePopup: (timeStart?: string, timeEnd?: string) => void;
};

const IndiceComponent: React.FC<thematicIndexProps> = React.memo(
  ({
    index,
    transcriptMetadata,
    onPlayQuote,
    onStop,
    onKeepPlaying,
    showSharePopup,
  }) => {
    const [accordionContent, setAccordionContent] = useState<Array<any>>([]);

    useEffect(() => {
      if (index.length) {
        setAccordionContent(
          index.map((quoteGroup, groupIndex) => (
            <Accordion.Panel key={groupIndex}>
              <Accordion.Title className="text-black p-0 pr-[6px] focus:ring-0 relative hover:bg-[#c6e2fa]">
                <div
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    const currentElem = event.currentTarget;
                    setTimeout(() => {
                      scrollToQuote(
                        currentElem.parentElement?.parentElement as HTMLElement
                      );
                    }, 50);
                  }}
                ></div>
                <div className="grid grid-cols-12 px-2 py-1 leading-[18px] @md:grid-cols-10">
                  <div className="col-span-1 col-end-2 @md:col-end-1 @md:w-[20px] @md:mr-[10px]">
                    {groupIndex + 1}.
                  </div>
                  <div className="col-span-11 @md:col-span-8">
                    {quoteGroup.title?.trim()}
                  </div>
                </div>
              </Accordion.Title>
              <Accordion.Content className="p-0">
                {quoteGroup.quotes?.map((quote, quoteIndex) => (
                  <div
                    key={`${groupIndex}_${quoteIndex}`}
                    id={`ualter-sound-quote-indice-${groupIndex}-${quoteIndex}`}
                  >
                    <Quote
                      type="thematicIndex"
                      articleFileName={transcriptMetadata.articleFileName}
                      date={transcriptMetadata.date}
                      place={transcriptMetadata.place}
                      groupIndex={groupIndex}
                      quoteIndex={quoteIndex}
                      start={quote.start}
                      end={quote.end}
                      words={quote.words}
                      speaker={transcriptMetadata.speaker}
                      showSharePopup={showSharePopup}
                      onPlay={() => {
                        onPlayQuote(
                          `ualter-sound-quote-indice-${groupIndex}-${quoteIndex}`,
                          quote.start,
                          quote.end
                        );
                      }}
                      onStop={onStop}
                      onKeepPlaying={() => onKeepPlaying(quote.end)}
                      isInIndice={true}
                    />
                  </div>
                ))}
              </Accordion.Content>
            </Accordion.Panel>
          ))
        );
      }
    }, [
      index,
      onPlayQuote,
      onStop,
      onKeepPlaying,
      transcriptMetadata.speaker,
      transcriptMetadata.date,
      transcriptMetadata.place,
      transcriptMetadata.articleFileName,
      showSharePopup,
    ]);

    return (
      <Accordion
        arrowIcon={ImageArrow}
        className="border-0 mb-[60px] divide-[red] divide-p divide-y-[2px] font-dmsans"
        collapseAll={true}
        alwaysOpen={false}
      >
        {accordionContent}
      </Accordion>
    );
  }
);

export default IndiceComponent;
