interface MySvgProps {
  className?: string;
  onClick?: () => void;
  color?: string;
}

const MySvgComponent: React.FC<MySvgProps> = ({
  className,
  onClick,
  color,
}) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    className={className}
    onClick={onClick}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <title></title>{" "}
      <g id="Complete">
        {" "}
        <g id="volume-up">
          {" "}
          <polygon
            fill="none"
            points="2.9 9 6.9 9 11.9 3 11.9 21 6.9 16 1.9 16 1.9 9 2.9 9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          ></polygon>{" "}
          <path
            d="M15.5,19.5a7.3,7.3,0,0,0,7-7.5,7.3,7.3,0,0,0-7-7.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          ></path>{" "}
          <path
            d="M15.5,15a3,3,0,0,0,0-6"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          ></path>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);

export default MySvgComponent;
