import React from "react";

interface BrandLogoProps {
  maxWidth?: string;
  maxHeight?: string;
}

const BrandLogo: React.FC<BrandLogoProps> = ({
  maxWidth = "80px",
  maxHeight = "25px",
}) => {
  const logoUrl = process.env.REACT_APP_LOGO_URL;
  const logoCtaUrl = process.env.REACT_APP_LOGO_CTA_URL;

  return (
    <a
      href={logoCtaUrl ? logoCtaUrl : "#"}
      className="select-none"
      style={{ maxWidth, maxHeight }}
    >
      <img src={logoUrl} alt="logo" style={{ maxWidth, maxHeight }} />
    </a>
  );
};

export default BrandLogo;
