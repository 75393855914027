import { FC, useCallback, useEffect, useState } from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import BrandLogo from "./BrandLogo";
import Embed from "../assets/Embed";

type SharePopupProps = {
  visible: boolean;
  articleId: string;
  startTime?: number;
  endTime?: number;
  speaker: { name: string; familyName: string };
  onClose: () => void;
  onCopy: (url: string) => void;
  onCopyEmbed: (code: string) => void;
};

const SharePopup: FC<SharePopupProps> = ({
  visible,
  articleId,
  startTime,
  endTime,
  speaker,
  onClose,
  onCopy,
  onCopyEmbed,
}) => {
  const [isVisible, setIsVisible] = useState(visible);
  const [isEmbedVisible, setIsEmbedVisible] = useState(false);

  const [urlToShare, setUrlToShare] = useState<string>(
    `${process.env.REACT_APP_DIRECT_WIDGET_BASE_URL}/${articleId}`
  );
  const [textToShare, setTextToShare] = useState<string>();

  useEffect(() => {
    let url = `${process.env.REACT_APP_DIRECT_WIDGET_BASE_URL}/${articleId}`;
    if (startTime && endTime) url += `?ts=${startTime}&te=${endTime}`;
    setUrlToShare(url);
    setTextToShare(`Accede a la transcripción completa en UalterSound`);
  }, [articleId, startTime, endTime, speaker]);

  useEffect(() => {
    if (!visible) {
      const timeout = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timeout);
    }
    setIsVisible(true);
  }, [visible]);

  const getUrlWithUTM = useCallback(
    (source: string, medium: string = "social") => {
      if (urlToShare.match(new RegExp("\\?.+&")))
        return `${urlToShare}&utm_source=${source}&utm_medium=${medium}`;
      if (urlToShare.match(new RegExp("\\?$")))
        return `${urlToShare}utm_source=${source}&utm_medium=${medium}`;
      return `${urlToShare}?utm_source=${source}&utm_medium=${medium}`;
    },
    [urlToShare]
  );

  if (!isVisible) return null;

  /* */

  //   const codeToEmbed =
  //     `<ualter-sound data-content-id='${articleId}'{TIMES}></ualter-sound>
  // <link rel='stylesheet' href='https://soundcontent.ualter.ai/ui/${process.env.REACT_APP_STAGE}/css/ualter-sound.css' />
  // <script defer='true' async='true' type='text/javascript' src='https://soundcontent.ualter.ai/ui/${process.env.REACT_APP_STAGE}/js/ualter-sound.js'></script>`.replace(
  //       "{TIMES}",
  //       startTime && endTime
  //         ? ` data-start-at='${startTime}' data-end-at='${endTime}'`
  //         : ""
  //     );

  const codeToEmbed = `<script>
    if (!document.querySelector('script[src="https://soundcontent.ualter.ai/ui/${process.env.REACT_APP_STAGE}/js/ualter-sound.js"]')) {
        const script = document.createElement('script');
        script.src = 'https://soundcontent.ualter.ai/ui/${process.env.REACT_APP_STAGE}/js/ualter-sound.js';
        document.head.appendChild(script);
    }
    if (!document.querySelector('link[href="https://soundcontent.ualter.ai/ui/${process.env.REACT_APP_STAGE}/css/ualter-sound.css"]')) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://soundcontent.ualter.ai/ui/${process.env.REACT_APP_STAGE}/css/ualter-sound.css';
        document.head.appendChild(link);
    }
</script>
<ualter-sound data-content-id='${articleId}'{TIMES}></ualter-sound>`.replace(
    "{TIMES}",
    startTime && endTime
      ? ` data-start-at='${startTime}' data-end-at='${endTime}'`
      : ""
  );

  const embedComponent = (
    <div className="flex space-x-2 w-full">
      <code
        className="code bg-[#3B3B3B] text-[white] w-full py-4
          p-[10px] max-h-[100px] rounded-md overflow-scroll whitespace-pre"
      >
        {codeToEmbed}
      </code>
      <div className="flex flex-col justify-center">
        <button
          className="p-[10px] rounded-md bg-[#f1f1f1]"
          onClick={() => onCopyEmbed(codeToEmbed)}
        >
          Copiar
        </button>
      </div>
    </div>
  );

  return (
    <div
      className={`absolute top-0 left-0 w-full h-full z-10 bg-black bg-opacity-50 flex
        justify-center items-center transition-opacity duration-300 ${
          visible ? "opacity-100" : "opacity-0"
        } rounded-[10px]`}
      onClick={onClose}
    >
      <div
        className="bg-white w-[90%] max-h-[90%] flex flex-col p-4 rounded-lg shadow-md space-y-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex w-full justify-between mb-[10px] items-center">
          <BrandLogo />
          <h1 className="text-lg">
            {isEmbedVisible ? "Incorporar" : "Compartir"}
          </h1>
          <div className="flex items-center space-x-2">
            <a
              href="https://ualtersound.ai"
              className="font-dmsans text-[14px] text-[#dd2a00]" //ualter-sound-header-link"
            >
              UalterSound
            </a>
            <button
              onClick={() => {
                if (isEmbedVisible) {
                  setIsEmbedVisible(false);
                  return;
                }
                onClose();
              }}
            >
              <span className="text-3xl" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
        </div>
        {isEmbedVisible ? (
          embedComponent
        ) : (
          <div className="flex flex-col space-y-4">
            <div className="flex w-full justify-evenly overflow-auto space-x-4 pb-4">
              <button
                onClick={() => setIsEmbedVisible(true)}
                className="flex flex-col items-center"
              >
                <div
                  className="flex ualter-sound-action-button rounded-full p-2'
                w-[48px] h-[48px] justify-center items-center"
                >
                  <Embed className="ualter-sound-svg-secondary" />
                </div>
                <p className="text-sm select-none">Incorporar</p>
              </button>
              <WhatsappShareButton
                url={getUrlWithUTM("whatsapp")}
                title={textToShare}
              >
                <div className="flex flex-col items-center">
                  <WhatsappIcon round size={48} />
                  <p className="text-sm">WhatsApp</p>
                </div>
              </WhatsappShareButton>
              <FacebookShareButton
                url={getUrlWithUTM("facebook")}
                title={textToShare}
              >
                <div className="flex flex-col items-center">
                  <FacebookIcon round size={48} />
                  <p className="text-sm">Facebook</p>
                </div>
              </FacebookShareButton>
              <TwitterShareButton url={getUrlWithUTM("x")} title={textToShare}>
                <div className="flex flex-col items-center">
                  <XIcon round size={48} />
                  <p className="text-sm">X</p>
                </div>
              </TwitterShareButton>
              <TelegramShareButton
                url={getUrlWithUTM("telegram")}
                title={textToShare}
              >
                <div className="flex flex-col items-center">
                  <TelegramIcon round size={48} />
                  <p className="text-sm">Telegram</p>
                </div>
              </TelegramShareButton>
              <LinkedinShareButton
                url={getUrlWithUTM("linkedin")}
                title={textToShare}
              >
                <div className="flex flex-col items-center">
                  <LinkedinIcon round size={48} />
                  <p className="text-sm">LinkedIn</p>
                </div>
              </LinkedinShareButton>
              <EmailShareButton
                url={getUrlWithUTM("email", "email")}
                title={textToShare}
              >
                <div className="flex flex-col items-center">
                  <EmailIcon round size={48} />
                  <p className="text-sm">Email</p>
                </div>
              </EmailShareButton>
            </div>
            <div className="flex space-x-2 w-full">
              <input
                readOnly
                value={urlToShare}
                className="bg-[#3B3B3B] text-[white] w-full p-[10px] rounded-md
                  focus:outline-none focus:ring-0"
              />
              <button
                className="p-[10px] rounded-md bg-[#f1f1f1]"
                onClick={() => onCopy(urlToShare)}
              >
                Copiar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SharePopup;
