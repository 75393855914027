import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ImagePlay from "../assets/PlayQuote";
import ImagePause from "../assets/PauseQuote";
import PlayVideo from "../assets/PlayVideo";
import ImageClose from "../assets/Close";
import ImageShare from "../assets/Share";
import { formatSecondsToHMS } from "./AudioPlayer";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { useQuotesContext } from "../context/QuotesContext";

export interface quoteInterface {
  word: string;
  start: number;
  end: number;
}

type QuoteProps = {
  type: "numbers" | "names" | "thematicIndex";
  groupIndex?: number;
  quoteIndex: number;
  speaker?: { name: string; familyName: string };
  place?: string;
  date?: string;
  articleFileName?: string;
  start: number;
  end: number;
  words: Array<quoteInterface>;
  onPlay: () => void;
  onStop: () => void;
  onKeepPlaying: () => void;
  isInIndice?: boolean;
  showSharePopup: (timeStart?: string, timeEnd?: string) => void;
};

const QuoteComponent: React.FC<QuoteProps> = React.memo(
  ({
    type,
    speaker,
    place,
    date,
    articleFileName,
    start,
    end,
    words,
    groupIndex,
    quoteIndex,
    onPlay,
    onStop,
    onKeepPlaying,
    isInIndice = false,
    showSharePopup,
  }) => {
    const [spansElem, setSpansElem] = useState<Array<any>>([]);
    const [videoUrl, setVideoUrl] = useState("");
    const [isVideoShowing, setIsVideoShowing] = useState(false);
    const videoRef = useRef<any>(null);
    const { updateCurrentQuote, currentQuote, resetCurrentQuote } =
      useQuotesContext();

    const isPlaying = useCallback(() => {
      return currentQuote === `${type}#${quoteIndex}#${groupIndex}`;
    }, [currentQuote, type, quoteIndex, groupIndex]);

    useEffect(() => {
      injectStyle();
    }, []);

    useEffect(() => {
      if (words.length)
        setSpansElem(
          words.map((word, index) => (
            <span
              key={index}
              data-ts={word.start}
              data-te={word.end}
              className="ualter-sound-speech-word"
            >
              {" "}
              {index === 0 ? '"' : ""}
              {word.word.trim()}
              {index === words.length - 1 ? '"' : ""}
            </span>
          ))
        );
    }, [words]);

    const videoPlayer = useMemo(
      () => (
        <div>
          <div
            className={`${isVideoShowing ? "block" : "hidden"} 
              mx-auto mt-4 pb-1 rounded-[12px] border-[1px]
              flex flex-col relative`}
          >
            <div
              className="absolute -top-2 -right-2 rounded-[6px] text-[#d61e00] z-10 bg-white
                cursor-pointer border-[1px] border-black border-opacity-[0.4] bg-opacity-[0.8]"
              onClick={() => {
                setIsVideoShowing(false);
                videoRef.current.pause();
              }}
            >
              <ImageClose />
            </div>

            <video
              src={
                videoUrl ? `${process.env.REACT_APP_VIDEO_URL}/${videoUrl}` : ""
              }
              ref={videoRef}
              className="rounded-t-[12px]"
              width="100%"
              height="250px"
              controls
            ></video>

            <div className="flex items-center">
              {/* <a
                href={`${process.env.REACT_APP_VIDEO_URL}/${videoUrl}`}
                download="UalterSound-video.mp4"
                className="ualter-sound-action-button px-[12px] py-[6px] w-[40%]
                  mx-auto font-bold text-[14px] rounded-[4px] text-center mt-2 mb-1"
              >
                Descargar
              </a> */}
              <button
                onClick={async () => {
                  await fetch(`${process.env.REACT_APP_VIDEO_URL}/${videoUrl}`)
                    .then((response) => response.blob())
                    .then((blob) => {
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement("a");
                      a.style.display = "none";
                      a.href = url;
                      a.download = "UalterSound-video.mp4";
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);
                    });
                }}
                className="ualter-sound-action-button px-[12px] py-[6px] w-[40%]
                  mx-auto font-bold text-[14px] rounded-[4px] text-center mt-2 mb-1"
              >
                Descargar
              </button>
              <div
                className="ualter-sound-action-button flex items-center cursor-pointer
                  justify-center gap-2 px-[12px] py-[6px] w-[40%] mx-auto font-bold
                  text-[14px] rounded-[4px] text-center mt-2 mb-1"
                onClick={() => {
                  if (navigator.share) {
                    try {
                      navigator.share({
                        title: `Compartido a través de UalterSound`,
                        text: `Accede a la transcripción completa en UalterSound`,
                        url: `${process.env.REACT_APP_VIDEO_URL}/${videoUrl}`,
                      });
                    } catch (error) {
                      console.error("Error sharing", error);
                      toast.error("Error al compartir", {
                        autoClose: 1500,
                        hideProgressBar: true,
                        style: {
                          border: "1px solid rgba(0, 0, 0, 0.5)",
                        },
                      });
                    }
                  } else {
                    if (!navigator.clipboard) return;
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_VIDEO_URL}/${videoUrl}`
                    );
                    toast.success("Vínculo copiado al portapapeles", {
                      autoClose: 1500,
                      hideProgressBar: true,
                      style: { border: "1px solid rgba(0, 0, 0, 0.5)" },
                    });
                  }
                }}
              >
                <div>Compartir</div>
                <ImageShare className="ualter-sound-svg-secondary" />
              </div>
            </div>
          </div>
        </div>
      ),
      [videoUrl, isVideoShowing]
    );

    return (
      <div
        className={`pl-2 pr-3 pt-1 pb-3 mb-2 mx-1
          font-dmsans leading-[1.2]
          ${quoteIndex === 0 ? "rounded-b-[12px]" : "rounded-[12px]"}`}
      >
        <div
          className={`grid grid-cols-10 gap-3 items-start mb-2 ${
            isVideoShowing ? "hidden" : "block"
          }
         @md:gap-0
          `}
        >
          {isPlaying() ? (
            <ImagePause
              className="col-span-2 col-end-2 w-[28px] mt-4 cursor-pointer"
              onClick={() => {
                resetCurrentQuote();
                onStop();
              }}
            />
          ) : (
            <ImagePlay
              className="col-span-2 col-end-2 w-[28px] mt-4 cursor-pointer"
              onClick={() => {
                updateCurrentQuote(type, quoteIndex, groupIndex);
                onPlay();
              }}
            />
          )}{" "}
          <div className="col-span-9">
            <div className="text-[12px] pt-1 pb-1">
              {formatSecondsToHMS(start)}
            </div>
            <div className="text-[15px] pb-1">
              {speaker?.name} {speaker?.familyName}
            </div>
            <div
              className={`font-[400]${
                isPlaying() ? " ualter-sound-speech-highlightable" : ""
              }`}
            >
              {spansElem}
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center relative">
          <div
            className={`absolute left-1/2 transform -translate-x-1/2 text-[15px] text-center cursor-pointer ${
              isInIndice && isVideoShowing ? "hidden" : "block"
            } ualter-sound-keep-listening`}
            onClick={() => {
              onStop();
              onKeepPlaying();
            }}
          >
            Seguir escuchando
          </div>
          <div className="flex ml-auto items-center">
            <ImageShare
              className={`w-[24px] h-[24px] cursor-pointer mr-2 ualter-sound-svg-primary ${
                isInIndice && isVideoShowing ? "hidden" : "block"
              }`}
              onClick={() => showSharePopup(start.toString(), end.toString())}
            />
            {isInIndice && (
              <div
                className={`flex justify-end pr-2 ${
                  isVideoShowing ? "hidden" : "block"
                }`}
              >
                {isInIndice && words.length > 0 && (
                  <PlayVideo
                    className="w-[36px] h-[36px] cursor-pointer ualter-sound-svg-primary"
                    onClick={() => {
                      setVideoUrl(
                        `${process.env.REACT_APP_STAGE}/quotes/${articleFileName},${groupIndex},${quoteIndex}.mp4`
                      );
                      setIsVideoShowing(true);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {isVideoShowing && videoPlayer}
      </div>
    );
  }
);

export default QuoteComponent;
