export interface SvgProps {
  className?: string;
  onClick?: () => void;
  color?: string;
  width?: number;
  height?: number;
  pathProps?: any;
}

const Svg: React.FC<SvgProps> = ({
  className,
  onClick,
  color = "#fff",
  pathProps,
  width = 16,
  height = 16,
}) =>
  pathProps ? (
    <svg
      xmlSpace="preserve"
      width={width}
      height={height}
      viewBox="0 0 512 512"
      className={className}
      onClick={onClick}
    >
      <path {...pathProps} />
    </svg>
  ) : null;

export default Svg;
