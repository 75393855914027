interface MySvgProps {
  className?: string;
  onClick?: () => void;
}

const MySvgComponent: React.FC<MySvgProps> = ({ className, onClick }) => (
  <svg
    fill="#000000"
    height="64px"
    width="64px"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 420.012 420.012"
    className={className}
    onClick={onClick}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g id="XMLID_9_">
        {" "}
        <path
          id="XMLID_348_"
          d="M0,40v340.012h420.012V40H0z M390,350H30.012V70.012H390V350z"
        ></path>{" "}
        <path
          id="XMLID_352_"
          d="M145,127.005v166.001l145.251-83.001L145,127.005z M229.76,210.006l-54.749,31.285v-62.569L229.76,210.006 z"
        ></path>{" "}
        <polygon
          id="XMLID_441_"
          points="360.006,135.006 360.006,105.006 360.006,105 320.006,105 320.006,105.006 320.006,135.006 320.006,135.012 360.006,135.012 "
        ></polygon>{" "}
        <polygon
          id="XMLID_443_"
          points="360.006,195.006 360.006,165.006 360.006,165 320.006,165 320.006,165.006 320.006,195.006 320.006,195.012 360.006,195.012 "
        ></polygon>{" "}
        <polygon
          id="XMLID_444_"
          points="360.006,255.006 360.006,225.006 360.006,225 320.006,225 320.006,225.006 320.006,255.006 320.006,255.012 360.006,255.012 "
        ></polygon>{" "}
        <polygon
          id="XMLID_445_"
          points="360.006,315.006 360.006,285.006 360.006,285 320.006,285 320.006,285.006 320.006,315.006 320.006,315.012 360.006,315.012 "
        ></polygon>{" "}
        <polygon
          id="XMLID_446_"
          points="100.006,135.006 100.006,105.006 100.006,105 60.006,105 60.006,105.006 60.006,135.006 60.006,135.012 100.006,135.012 "
        ></polygon>{" "}
        <polygon
          id="XMLID_447_"
          points="100.006,195.006 100.006,165.006 100.006,165 60.006,165 60.006,165.006 60.006,195.006 60.006,195.012 100.006,195.012 "
        ></polygon>{" "}
        <polygon
          id="XMLID_448_"
          points="100.006,255.006 100.006,225.006 100.006,225 60.006,225 60.006,225.006 60.006,255.006 60.006,255.012 100.006,255.012 "
        ></polygon>{" "}
        <polygon
          id="XMLID_449_"
          points="100.006,315.006 100.006,285.006 100.006,285 60.006,285 60.006,285.006 60.006,315.006 60.006,315.012 100.006,315.012 "
        ></polygon>{" "}
      </g>{" "}
    </g>
  </svg>
);

export default MySvgComponent;
