import axios from "axios";
import {
  FC,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  SyntheticEvent,
} from "react";
import Header from "../components/header";
import NavItem from "../components/NavItem";
import Speech, { whisperWordInterface } from "../components/tabs/Speech";
import Indice, { thematicIndexInterface } from "../components/tabs/Indice";
import Datos, { datosInterface } from "../components/tabs/Datos";
import Nombres, { nombresInterface } from "../components/tabs/Nombres";
import AudioPlayer, { AudioPlayerMethods } from "../components/AudioPlayer";
import ModuleEmpty from "../components/tabs/ModuleEmpty";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Spinner } from "flowbite-react";
import "../index.css";
import SharePopup from "../components/SharePopup";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import {
  PlayingContextProvider,
  usePlayingContext,
} from "../context/PlayingContext";
import {
  QuotesContextProvider,
  useQuotesContext,
} from "../context/QuotesContext";
import ShareButton from "../components/ShareButton";

const Home: FC<{
  articleId: string;
  hash: string;
  startTime?: number;
  endTime?: number;
}> = ({ articleId, hash, startTime, endTime }) => {
  const { resetCurrentQuote } = useQuotesContext();
  const [summary, setSummary] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [speaker, setSpeaker] = useState<{ name: string; familyName: string }>({
    name: "",
    familyName: "",
  });
  const [lugar, setLugar] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [words, setWords] = useState<Array<whisperWordInterface>>([]);
  const [indice, setIndice] = useState<Array<thematicIndexInterface>>([]);
  const [datos, setDatos] = useState<Array<datosInterface>>([]);
  const [nombres, setNombres] = useState<Array<nombresInterface>>([]);
  const [image, setImage] = useState("");
  const [epigraph, setEpigraph] = useState("");
  const [scrollPositions, setScrollPositions] = useState<Array<number>>([]);
  const navSeparator = <div className={`font-normal @md:px-2`}>|</div>;

  const containerRef = useRef<HTMLDivElement>(null);
  const audioPlayerRef = useRef<AudioPlayerMethods>(null);
  const [isAudioPlayerVisible, setIsAudioPlayerVisible] = useState(true);

  const isFragment = Boolean(startTime && endTime);

  const [isLoading, setIsLoading] = useState(true);
  const [animationClass, setAnimationClass] = useState("fade-enter");
  const [dataLoadingError, setDataLoadingError] = useState(false);

  const [shareButtonPosition, setShareButtonPosition] = useState<{
    top?: number;
    bottom?: string;
  } | null>(null);
  const [sharePopupVisible, setSharePopupVisible] = useState(false);
  const [timesToShare, setTimesToShare] = useState<{
    start: number;
    end: number;
  } | null>(null);

  const [scrollToCurrentVisible, setScrollToCurrentVisible] = useState(false);
  const { enableAutoScroll } = usePlayingContext();

  useEffect(() => {
    injectStyle();
  }, []);

  useEffect(() => {
    setIsLoading(true);

    if (startTime && endTime)
      setTimesToShare({ start: startTime, end: endTime });
    else setTimesToShare(null);

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    axiosInstance
      .get(`/fetch/${articleId}`)
      .then((response: any) => {
        setSummary(response.data.resumen.resumen);
        setTitle(response.data.resumen.titulo);
        setSpeaker({
          name: response.data.nombre,
          familyName: response.data.apellido,
        });
        setDate(response.data.date);
        setLugar(response.data.lugar);

        if (isFragment)
          setWords(
            response.data.timestamps.filter(
              (item: any) => item.start >= startTime! && item.start < endTime!
            )
          );
        else setWords(response.data.timestamps);

        setImage(response.data.image);
        setEpigraph(response.data.epigraph);
        setIndice(
          response.data.indice_tematico?.map((item: any) => ({
            ...item,
            title: item.titulo,
            quotes: item.textuales,
          }))
        );
        setDatos(response.data.numeros);
        setNombres(response.data.nombres);
      })
      .catch((e) => {
        console.log(e);
        setDataLoadingError(true);
      })
      .finally(() => setIsLoading(false));
  }, [articleId, startTime, endTime, isFragment]);

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    const mainContainer = document.getElementById(`usc-${articleId}-${hash}`);
    if (!mainContainer) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) onStop();
      });
    }, options);

    observer.observe(mainContainer);

    return () => observer.unobserve(mainContainer);
  }, [articleId, hash]);

  const onStop = async () => await audioPlayerRef.current?.onStop();

  const scrollToElement = useCallback(
    (target: HTMLElement) => {
      const speechContainer = document.querySelector(
        `#ualter-sound-speech-${articleId}-${hash}`
      );
      if (!speechContainer) return;
      if (!target) speechContainer.scrollTop = 0;
      else speechContainer.scrollTop = target.offsetTop;
    },
    [articleId, hash]
  );

  const onStopQuote = onStop;

  const onKeepPlaying = useCallback(
    async (timestamp: number) => {
      setCurrentTab(0);
      resetCurrentQuote();
      await audioPlayerRef.current?.onPlay(timestamp + 0.5, true, false);
    },
    [resetCurrentQuote]
  );

  const showSharePopup = useCallback((timeStart?: string, timeEnd?: string) => {
    if (timeStart && timeEnd)
      setTimesToShare({
        start: parseFloat(timeStart),
        end: parseFloat(timeEnd),
      });
    else setTimesToShare(null);

    setSharePopupVisible(true);
  }, []);

  const handleNavClick = (tab: number) => {
    const currentScrollPosition =
      document.getElementById(`ualter-sound-speech-${articleId}-${hash}`)
        ?.scrollTop || 0;
    setScrollPositions((prevPositions) => {
      const newPositions = [...prevPositions];
      newPositions[currentTab] = currentScrollPosition;
      return newPositions;
    });

    if (tab !== 0) {
      onStop();
    } else {
      resetCurrentQuote();
      audioPlayerRef.current?.onResetStopTimestamp();
    }

    setAnimationClass("fade-exit");
    setTimeout(() => {
      setCurrentTab(tab);
      setAnimationClass("fade-enter");
    }, 150);
  };

  useEffect(() => {
    const scrollPosition = scrollPositions[currentTab];
    const element = document.getElementById(
      `ualter-sound-speech-${articleId}-${hash}`
    );
    element?.scrollTo(0, scrollPosition);
  }, [currentTab, scrollPositions, articleId, hash]);

  useEffect(() => {
    // This useEffect sets up an IntersectionObserver to monitor the visibility of the main container.
    // The observer checks the intersection ratio of the container with the viewport.
    // If the visible part of the container is less than 20% (intersectionRatio < 0.2),
    // it hides the AudioPlayer. The observer uses a threshold array with values from 0 to 1 in
    // increments of 0.05 to provide more granular visibility changes.
    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          if (entry.intersectionRatio < 0.2) setIsAudioPlayerVisible(false);
          else setIsAudioPlayerVisible(true);
        }),
      { threshold: Array.from({ length: 21 }, (_, i) => i * 0.05) }
    );

    const currentContainer = containerRef.current;

    if (currentContainer) observer.observe(currentContainer);

    return () => {
      if (currentContainer) observer.unobserve(currentContainer);
    };
  }, []);

  const mainComponent = useMemo(() => {
    const onPlayQuote = (
      quoteContainerId: string,
      start: number,
      end: number
    ) => {
      audioPlayerRef.current?.onPlayQuote(start, quoteContainerId, end);
    };

    switch (currentTab) {
      case 1:
        return (
          <Indice
            transcriptMetadata={{
              place: lugar,
              date: date,
              articleFileName: articleId,
              speaker: speaker,
            }}
            index={indice}
            onPlayQuote={onPlayQuote}
            onStop={onStopQuote}
            onKeepPlaying={onKeepPlaying}
            showSharePopup={showSharePopup}
          />
        );
      case 2:
        return datos.length ? (
          <Datos
            datos={datos}
            speaker={speaker}
            onPlayQuote={onPlayQuote}
            onStop={onStopQuote}
            onKeepPlaying={onKeepPlaying}
            showSharePopup={showSharePopup}
          />
        ) : (
          <ModuleEmpty text="No hay datos en este discurso" />
        );
      case 3:
        return nombres.length ? (
          <Nombres
            nombres={nombres}
            speaker={speaker}
            onPlayQuote={onPlayQuote}
            onStop={onStopQuote}
            onKeepPlaying={onKeepPlaying}
            showSharePopup={showSharePopup}
          />
        ) : (
          <ModuleEmpty text="No hay nombres en este discurso" />
        );
    }
  }, [
    currentTab,
    lugar,
    date,
    articleId,
    speaker,
    indice,
    datos,
    nombres,
    onStopQuote,
    onKeepPlaying,
    showSharePopup,
  ]);

  function handleScroll(event: SyntheticEvent): void {
    const _s = scrollPositions;
    _s[currentTab] = event.currentTarget.scrollTop;
    setScrollPositions(_s);
  }

  const handleSelectText = useCallback(
    (timeStart: number, timeEnd: number, y: number | null) => {
      setTimesToShare({ start: timeStart, end: timeEnd });
      // if (y) setShareButtonPosition({ top: y });
      // else setShareButtonPosition({ bottom: "20%" });
      // TODO fix this. it bugs when scrollTop > 0
      setShareButtonPosition({ bottom: "20%" });
    },
    []
  );

  useEffect(() => {
    const shareButton = document.getElementById(
      `ualter-sound-share-button-${articleId}-${hash}`
    );
    if (!shareButton) return;

    if (isFragment || !shareButtonPosition) shareButton.style.display = "none";
    else shareButton.style.display = "block";
  }, [isFragment, shareButtonPosition, articleId, hash]);

  return (
    <div
      ref={containerRef}
      className="ualter-sound-wrapper @container p-1 !bg-white"
      id={`ualter-sound-wrapper-${articleId}-${hash}`}
    >
      <div
        className={`flex flex-col !min-h-[200px]
          w-full max-w-[600px] ${isFragment ? "h-auto" : "max-h-[90dvh] h-full"}
          rounded-[12px] mx-auto relative justify-center
          border-[1px] ualter-sound-main-container`}
        onClick={() => setShareButtonPosition(null)}
      >
        <ToastContainer position="top-center" />
        <div
          className="absolute left-1/2 transform -translate-x-1/2 z-10 hidden"
          style={shareButtonPosition ? shareButtonPosition : {}}
          id={`ualter-sound-share-button-${articleId}-${hash}`}
        >
          <ShareButton
            onClick={() => {
              window.getSelection()?.removeAllRanges();
              setShareButtonPosition(null);
              setSharePopupVisible(true);
            }}
          />
        </div>
        <SharePopup
          visible={sharePopupVisible}
          startTime={timesToShare?.start}
          endTime={timesToShare?.end}
          articleId={articleId}
          speaker={speaker}
          onClose={() => setSharePopupVisible(false)}
          onCopy={(url) => {
            navigator.clipboard.writeText(url);
            toast.success("Vínculo copiado al portapapeles", {
              autoClose: 1500,
              hideProgressBar: true,
              style: { border: "1px solid rgba(0, 0, 0, 0.5)" },
            });
            setSharePopupVisible(false);
          }}
          onCopyEmbed={(code) => {
            navigator.clipboard.writeText(code);
            toast.success("Código copiado al portapapeles", {
              autoClose: 1500,
              hideProgressBar: true,
              style: { border: "1px solid rgba(0, 0, 0, 0.5)" },
            });
          }}
        />
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <Spinner size="lg" className="ualter-sound-spinner" />
          </div>
        ) : (
          <>
            <Header
              articleNotFound={dataLoadingError}
              date={date}
              speaker={speaker}
              title={title}
              summary={summary}
              isExpanded={isExpanded}
              image={image}
              epigraph={epigraph}
              onClick={() => setIsExpanded(!isExpanded)}
              isFragment={isFragment}
            />
            {!isFragment && !dataLoadingError && (
              <nav
                className="flex justify-center @sm:justify-evenly text-[#101010] break-all
                  font-dmsans font-semibold text-[17px] mt-2 px-[5px] @xs:px-[14px] mb-2 space-x-1"
              >
                <NavItem
                  isSelected={currentTab === 0}
                  onClick={() => handleNavClick(0)}
                  text="Discurso"
                />
                {navSeparator}
                <NavItem
                  isSelected={currentTab === 1}
                  onClick={() => handleNavClick(1)}
                  text="Índice"
                />
                {navSeparator}
                <NavItem
                  isSelected={currentTab === 2}
                  onClick={() => handleNavClick(2)}
                  text="Datos"
                />
                {navSeparator}
                <NavItem
                  isSelected={currentTab === 3}
                  onClick={() => handleNavClick(3)}
                  text="Nombres"
                />
              </nav>
            )}
            <QuotesContextProvider>
              <div
                className={`flex flex-col ${
                  !isFragment && "grow ualter-sound-speech-container"
                } overflow-y-auto
                  transition-transform duration-300 ease-in-out ${animationClass}`}
                id={`ualter-sound-speech-${articleId}-${hash}`}
                onScroll={handleScroll}
              >
                <div className={currentTab === 0 ? "block" : "hidden"}>
                  <Speech
                    words={words}
                    onClick={async (start) =>
                      await audioPlayerRef.current?.onPlay(start, true, false)
                    }
                    isFragment={isFragment}
                    fullSpeechURL={`${process.env.REACT_APP_DIRECT_WIDGET_BASE_URL}/${articleId}`}
                    handleSelectText={handleSelectText}
                    articleId={articleId}
                    hash={hash}
                  />
                </div>
                <div className={currentTab > 0 ? "block" : "hidden"}>
                  {mainComponent}
                </div>
              </div>
              <div
                className={`${
                  !scrollToCurrentVisible ? "hidden " : ""
                } bottom-[10%] left-[50%] -translate-x-[50%] absolute justify-center
                ualter-sound-scroll-to-current z-10`}
              >
                <div
                  className="popup w-fit select-none shadow-lg bg-white border-[2px] border-[#ccc]
                    px-3 py-1 z-[1000] rounded-[20px] flex items-center justify-between"
                >
                  <button
                    className="flex items-center"
                    onClick={() => {
                      enableAutoScroll();
                      scrollToElement(
                        document.querySelector(
                          `#ualter-sound-speech-${articleId}-${hash} div.ualter-sound-speech-transcript .ualter-sound-is-playing`
                        ) as HTMLElement
                      );
                    }}
                  >
                    Volver a centrar
                  </button>
                </div>
              </div>
              <div className={isAudioPlayerVisible ? "sticky bottom-0" : ""}>
                <PlayingContextProvider>
                  <AudioPlayer
                    disabled={dataLoadingError}
                    ref={audioPlayerRef}
                    articleId={articleId}
                    widgetHash={hash}
                    startTime={startTime}
                    endTime={endTime}
                    scrollToElement={scrollToElement}
                    beforePlay={() => setIsExpanded(false)}
                    showSharePopup={showSharePopup}
                    setScrollToCurrentVisible={setScrollToCurrentVisible}
                  />
                </PlayingContextProvider>
              </div>
            </QuotesContextProvider>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
