import React, { createContext, useContext, useState } from "react";

const QuotesContext = createContext({
  currentQuote: "",
  updateCurrentQuote: (
    type: string,
    quoteIndex: number,
    groupIndex?: number
  ) => {},
  resetCurrentQuote: () => {},
});

export const QuotesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentQuote, setCurrentQuote] = useState<string>("");

  const updateCurrentQuote = (
    type: string,
    quoteIndex: number,
    groupIndex?: number
  ) => {
    const quote = `${type}#${quoteIndex}#${groupIndex}`;
    setCurrentQuote(quote);
  };

  const resetCurrentQuote = () => {
    setCurrentQuote("");
  };

  return (
    <QuotesContext.Provider
      value={{
        currentQuote,
        updateCurrentQuote,
        resetCurrentQuote,
      }}
    >
      {children}
    </QuotesContext.Provider>
  );
};

export const useQuotesContext = () => {
  return useContext(QuotesContext);
};
